import { Contract } from "../types/entities";

const contracts = (): Contract[] => {
  return [
    {
      id: "632aee391df59f7f0b5ba924",
      title: "Äktenskapsförord",
      home: "För många är det en trygghet att redan ha bestämt hur allt ska fördelas i händelse av skilsmässa eller dödsfall.",
      main: "Utan ett äktenskapsförord räknas värdet av er egendom ihop och delas på hälften. Det gäller även egendom ni hade före ni gifte er samt eget sparande, arv och företag. I ett äktenskapsförord bestämmer ni vad som är rättvist just för er.",
      mainCta: "Skapa ett äktenskapsförord",
      progress: "Det här vill vi ska vara enskild egendom i vårt äktenskap",
      price: 119500,
      visible: 1,
      type: 7,
    },
    {
      id: "632aee381df59f7f0b5ba91f",
      title: "Skuldebrev",
      home: "När du lånar ut pengar till någon eller själv tar ett lån ska det alltid skrivas ett skuldebrev.",
      main: "Ett skuldebrev är det juridiska namnet för det som till vardags brukar kallas för låneavtal. Skuldebrevet är dels ett kvitto, dels ett bevis på att ett lån har tagits och existerar. Utan skuldebrev finns det en risk att du inte får tillbaka hela summan.",
      mainCta: "Skapa ett skuldebrev",
      progress: "Ett kvitto och bevis på att ett lån har tagits",
      price: 89500,
      visible: 1,
      type: 8,
    },
    {
      id: "632aee391df59f7f0b5ba920",
      title: "Gåvobrev Fastighet",
      home: "Ett gåvobrev är juridiskt bindande och måste upprättas när du ger bort en fastighet eller bostadsrätt.",
      main: "När du ger bort en fastighet eller bostadsrätt måste ett gåvobrev upprättas. Gåvobrevet är juridiskt bindande och bevisar att fastigheten är en gåva. Du kan även ange om gåvan ska vara enskild egendom och inte förskott på arv.",
      mainCta: "Skapa ett gåvobrev för fastighet",
      progress: "Ett gåvobevis samt vilka villkor som gäller för gåvan",
      price: 119500,
      visible: 1,
      type: 9,
    },
    {
      id: "632aee391df59f7f0b5ba923",
      title: "Samboavtal",
      home: "Vill du och din sambo ha en annan fördelning än vad lagen säger behöver ni ett samboavtal.",
      main: "Om du och din sambo saknar ett samboavtal gäller sambolagen. Vid en separation delas allt som har köpts för gemensamt bruk på hälften. Samma gäller vid dödsfall. Vill ni ha en annan fördelning än vad lagen säger behöver ni ett samboavtal.",
      mainCta: "Skapa ett samboavtal",
      progress: "Så här ska det som vi skaffat gemensamt fördelas",
      price: 79500,
      visible: 1,
      type: 10,
    },
    {
      id: "632d75181df59f7f0b5ba962",
      title: "Framtidsfullmakt",
      home: "I en framtidsfullmakt talar du om vem som får ta ansvar för dig och dina tillgångar när du inte kan göra det själv. ",
      main: "Om du blir sjuk, skadad eller på något annat sätt förlorar förmågan att ta hand om dig själv och dina tillgångar kan du – genom att skriva en framtidsfullmakt – själv bestämma vem eller vilka som får ta ansvar för dig och ditt liv.",
      mainCta: "Skapa en framtidsfullmakt",
      progress: "Vem som tar ansvar för mig när jag inte kan göra det själv",
      price: 119500,
      visible: 1,
      type: 11,
    },
    {
      id: "632d79cfd0eeeb308cfa403a",
      title: "Testamente",
      home: "Med ett testamente kan du vara trygg med att det du lämnar efter dig fördelas enligt din vilja.",
      main: "Genom att skriva ett testamente kan du säkerställa vad som ska hända med dina tillgångar när du inte längre finns i livet. Ett tydligt testamente underlättar också för dina nära och kära och minskar risken för eventuella framtida konflikter.",
      mainCta: "Skapa ett testamente",
      progress: "Så här vill jag att det jag lämnar efter mig ska fördelas",
      price: 139500,
      visible: 1,
      type: 12,
    },
    {
      id: "632d79cfd0eeeb308cfa403b",
      title: "Gåvobrev Lös egendom",
      home: "Med ett gåvobrev förebygger du framtida oklarheter. I avtalet kan du även ange särskilda villkor för din gåva.",
      main: "Med ett gåvobrev kan du förebygga framtida oklarheter och konflikter. Därför är det alltid bra att skriva ett gåvobrev när du ger bort eller får något av större värde. I gåvobrevet kan du även skriva med om det finns särskilda villkor för gåvan.",
      mainCta: "Skapa ett gåvobrev för lös egendom",
      progress: "Ett gåvobevis samt vilka villkor som gäller för gåvan",
      price: 119500,
      visible: 1,
      type: 13,
    },
  ];
};

export default contracts;
