import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";

export default function Layout() {
  return (
    <main
      className="relative flex flex-col flex-grow bg-dark-blue bg-main bg-no-repeat bg-[center_top_-100px] max-h-screen overflow-hidden"
      style={{ backgroundSize: "100% auto" }}
    >
      <Header />
      <div className="container flex flex-col flex-grow mx-auto px-3 sm:px-5 overflow-y-auto pt-[80px] xl:pt-[140px] 2xl:pt-[220px]">
        <Outlet />
      </div>
    </main>
  );
}
