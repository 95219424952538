import React, { useEffect, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import TrustBox from "../components/TrustBox";
import contracts from "../services/contracts";
import { getPhoneNumber } from "../services/utils";

const heroImage = new URL("../assets/images/hero.png", import.meta.url);
const docIcon = new URL("../assets/images/doc.svg", import.meta.url);
const phoneIcon = new URL("../assets/images/phone-icon.svg", import.meta.url);
const logoFullDark = new URL(
  "../assets/images/logo-full-dark.svg",
  import.meta.url
);

export default function Index() {
  const navigate = useNavigate();
  const [phone, phoneFull, replacePhoneCTM] = getPhoneNumber();

  useEffect(() => {
    document.title = "Avtalshjälpen - Fenix";
  }, []);

  useLayoutEffect(() => {
    replacePhoneCTM();
  }, []);

  return (
    <>
      <div className="container flex flex-col flex-grow mx-auto px-3 sm:px-5">
        <div className="mt-[100px] lg:mt-[200px] xl:mt-[260px] 2xl:mt-[320px] mb-10 lg:mb-14">
          <h1 className="font-pprightgothic text-[36px] lg:text-[56px] leading-none text-white mb-3">
            Avtalshjälpen
          </h1>
          <div className="text-white">
            Juridiskt korrekta avtal på bara några klick.
          </div>
        </div>
        <div className="rounded-xl bg-white text-dark-blue flex flex-col flex-col-reverse lg:flex-row">
          <div className="p-3 lg:p-10">
            <h2 className="font-pprightgothic text-[24px] lg:text-[40px] leading-none mb-3 lg:mb-5">
              Trygg inför framtiden
            </h2>
            <div className="mb-5">
              Att ha det som är överenskommet nedskrivet – svart på vitt – är en
              trygghet för alla berörda parter. Vissa skriftliga avtal kräver en
              särskild ordning och innehåll för att vara juridiskt bindande. För
              att göra det enkelt för dig och din familj, har vi på Fenix tagit
              fram ett verktyg för att du ska kunna skapa sådana avtal på egen
              hand.
            </div>
            <div>
              Det enda du behöver göra är att välja avtalet du vill skapa här
              nedanför och besvara ett antal frågor. Det färdiga avtalet skickas
              sedan till dig via e-post. Har du några frågor eller funderingar
              är du naturligtvis välkommen att höra av dig till oss.
            </div>
          </div>
          <div className="max-h-[300px] flex overflow-hidden rounded-t-xl lg:rounded-r-xl lg:block lg:max-h-fit lg:overflow-visible">
            <img
              src={heroImage.href}
              alt="Fenix"
              className="rounded-t-xl lg:rounded-l-none lg:rounded-r-xl w-[100%] h-fit relative lg:max-w-fit lg:w-auto lg:h-full"
            />
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute w-full h-full bg-homeCurve bg-no-repeat bg-cover z-0 top-0 opacity-10 lg:opacity-40"></div>
        <div className="relative py-16 lg:py-28 container mx-auto px-3 sm:px-5">
          <div className="flex justify-end">
            <div className="max-w-2xl">
              <h2 className="font-pprightgothic text-[24px] lg:text-[40px] text-white leading-none mb-3 lg:mb-5">
                Fenix – alltid vid din sida
              </h2>
              <div className="mb-5 text-white">
                Med gedigen erfarenhet, empatiska medarbetare och smarta
                digitala tjänster finns vi på Fenix här för att erbjuda dig
                guidning och stöttning genom livets alla faser.
              </div>
              <div className="text-white">
                Hos oss jobbar duktiga jurister och personligt engagerade
                rådgivare som – med stor omsorg och förståelse för hur svårt
                saker och ting kan kännas – hjälper dig precis så mycket eller
                lite du vill och behöver.
              </div>
            </div>
          </div>
        </div>
        <div className="relative container mx-auto px-3 sm:px-5">
          <h2 className="font-pprightgothic text-[24px] lg:text-[40px] leading-none text-white mb-3 lg:mb-5">
            Sju viktiga avtal
          </h2>
          <div className="grid gap-5 grid-cols-1 lg:grid-cols-3">
            {contracts().map((el) => {
              if (el.visible === 0) {
                return null;
              }
              const url = `/contract/${el.id}`;
              return (
                <div
                  key={el.id}
                  className="rounded-xl bg-white text-dark-blue p-4 lg:p-7 flex flex-col items-center justify-center"
                >
                  <img
                    src={docIcon.href}
                    alt="Fenix"
                    className="mb-3"
                    width={120}
                    height={120}
                  />

                  <Link
                    to={url}
                    className="text-[20px] lg:text-[26px] mb-3 font-interbold text-center leading-tight imp-arg-title"
                  >
                    {el.title}
                  </Link>
                  <div className="text-center text-sm mb-5">{el.home}</div>
                  <Button
                    theme="yellow-transparent"
                    title="Visa mer"
                    cssCLasses="imp-arg-cta"
                    onClick={() => navigate(url)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="sm:bg-homeInherit bg-no-repeat bg-left">
        <div
          className="bg-homeCard bg-no-repeat bg-contain"
          style={{ backgroundPosition: "right -250px center" }}
        >
          <div className="container flex flex-col mx-auto px-3 sm:px-5 py-5">
            <div className="py-20 lg:py-40 max-w-2xl">
              <h2 className="font-pprightgothic text-[24px] lg:text-[40px] leading-none text-white mb-3 lg:mb-5">
                Vem ärver mig?
              </h2>
              <div className="mb-5 text-white">
                Att behöva tänka på vad som händer med ens egendom den dagen man
                dör kan kännas jobbigt och svårt. Att redan nu ta reda på vad
                som händer i just din situation kan underlätta både för dig och
                dina nära och kära. Svara på våra frågor och se hur ditt arv
                kommer att fördelas och få även svar på vad du kan göra ifall du
                skulle vilja ändra på arvsordningen.
              </div>
              <Button
                title="Starta"
                cssCLasses="quiz-start"
                onClick={() =>
                  (window.location.href =
                    "https://fenixbegravning.se/arvssnurran/?paketmodal=1")
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-5 lg:py-10">
          <div className="text-center mb-5">
            <h2 className="font-pprightgothic text-[24px] lg:text-[40px] leading-none text-dark-blue mb-3 lg:mb-5">
              Våra omdömen
            </h2>
            <div className="font-interbold text-dark-blue">
              Se vad våra kunder har att säga
            </div>
          </div>
          <TrustBox />
        </div>
      </div>
      <div className="bg-white py-5 lg:py-10">
        <div className="container mx-auto bg-white text-dark-blue flex flex-col lg:flex-row justify-between items-center px-3 sm:px-5">
          <img
            src={logoFullDark.href}
            alt="Fenix"
            className="cursor-pointer h-fit max-w-none mb-3 lg:mb-0"
            width={216}
            height={60}
            onClick={() =>
              (window.location.href = "https://fenixbegravning.se/")
            }
          />
          <div>
            <div className="text-dark-blue font-pprightgothic text-[30px] leading-none mb-1">
              Hur kan vi hjälpa dig?
            </div>
            <a
              href={`tel:${phoneFull}`}
              className="font-interbold text-medium-blue text-[30px] flex items-center gap-2 footer-phone"
            >
              <img src={phoneIcon.href} alt="" />
              <p>{phone}</p>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-dark-blue py-5 lg:py-10">
        <div className="container mx-auto text-white flex flex-col lg:flex-row items-center justify-center gap-3 lg:gap-6 text-[16px] leading-none">
          <span>FENIX FAMILY AB © 2014 - 2022</span>
          <span className="hidden lg:block">|</span>
          <a
            href="https://fenixbegravning.se/wp-content/uploads/2020/10/Allmanna-villkor-2020-10-06.pdf"
            className="underline"
          >
            ALLMÄNNA VILLKOR
          </a>
          <span className="hidden lg:block">|</span>
          <a
            href="https://fenixbegravning.se/wp-content/uploads/2022/01/fenix_integritetspolicy_kund_uppdaterad_2022-01-26.pdf"
            className="underline"
          >
            INTEGRITETSPOLICY
          </a>
        </div>
      </div>
    </>
  );
}
