import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import contracts from "../services/contracts";
import { getContract } from "../services/ida";
import { generateRandomString } from "../services/utils";
import { Contract, ContractSet } from "../types/entities";

export default function HierarchyPage() {
  const [data, setData] = useState<ContractSet>();
  const [hierarchy, setHierarchy] = useState([]);
  const [questions, setQuestions] = useState();
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState<Contract | Record<string, never>>(
    {}
  );
  const { contractId } = useParams<{ contractId: string }>();

  useEffect(() => {
    const res = contracts().filter((el) => {
      return el.id === contractId;
    });
    if (res.length === 0) {
      return;
    }
    setContract(res[0]);
  }, []);

  useEffect(() => {
    document.title = contract.title;
  }, [contract]);

  useEffect(() => {
    if (data != undefined) {
      return;
    }
    fetchData(contract.id).catch((error) => {
      console.log(error);
    });
  }, [contract]);

  const fetchData = async (id: string) => {
    await getContract(id)
      .then((dt) => {
        setData(dt);
        setQuestions(dt.questions);

        const hierarchyFlat = [];
        for (const [k, v] of Object.entries(dt.hierarchy)) {
          for (const [i, obj] of Object.entries(v)) {
            hierarchyFlat.push(obj);
          }
        }
        setHierarchy(hierarchyFlat);
      })
      .catch((error: Error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getQuestionById = (id: string) => {
    const res = questions.filter((element: object) => {
      return element._id === id;
    });

    return res.length > 0 ? res[0] : false;
  };

  const renderHierarchyLevel = (hId, d) => {
    const res = hierarchy.filter((element) => {
      return element.id === hId && element.depth === d;
    });
    if (res.length === 0) {
      return false;
    }

    const hObj = res[0];
    // console.log("hObj", res[0]);
    const question = getQuestionById(hObj.id);
    const depth = hObj.depth;

    const fields = question.fields.map((f) => {
      const id = f._id;
      let nested = null;
      if (hObj.fids[id] !== undefined && hObj.fids[id].length > 0) {
        nested = hObj.fids[id].map((nid) => {
          return renderHierarchyLevel(nid, depth + 1);
        });
      }
      return (
        <div key={generateRandomString()}>
          <span className="">{f.label}</span>{" "}
          <span className="text-xs text-gray-300">{f._id}</span>
          {nested}
        </div>
      );
    });

    return (
      <div
        key={generateRandomString()}
        style={{ paddingLeft: depth, marginBottom: 10 }}
      >
        <span className="font-bold">
          ({depth}) {question.label}
        </span>{" "}
        <span className="text-xs text-gray-300">{question._id}</span>
        <div style={{ paddingLeft: depth + 25 }}>{fields}</div>
      </div>
    );
  };

  const renderHierarchyTree = () => {
    // console.log("hierarchy", hierarchy);
    return hierarchy.map((el) => {
      return el.depth === 0 ? renderHierarchyLevel(el.id, el.depth) : false;
    });
  };

  return (
    <div className="text-white bg-[#002733] p-10 rounded-xl opacity-80">
      <div className="mb-3">
        <Link to="/">◀︎ back</Link>
      </div>
      {!loading && data !== undefined ? (
        <>
          <h1 className="text-xl font-bold mb-3">{contract.title} hierarchy</h1>

          {renderHierarchyTree()}

          <h1 className="text-xl font-bold mb-3 mt-10">Hierarchy object</h1>
          <pre className="text-xs overflow-auto w-full border max-h-[500]">
            {JSON.stringify(data.hierarchy, null, 2)}
          </pre>

          <h1 className="text-xl font-bold mb-3 mt-10">Questions object</h1>
          <pre className="text-xs overflow-auto w-full border max-h-[500]">
            {JSON.stringify(data.questions, null, 2)}
          </pre>
        </>
      ) : (
        <p className="text-sm">loading</p>
      )}
    </div>
  );
}
