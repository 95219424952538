import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";

export default function LayoutHomepage() {
  return (
    <main
      className="relative flex flex-col flex-grow bg-dark-blue bg-main bg-no-repeat bg-[left_top] lg:bg-[left_top_-50px] xl:bg-[left_top_-100px]"
      style={{ backgroundSize: "100% auto" }}
    >
      <Header />
      <Outlet />
    </main>
  );
}
