import React, { useEffect, useState } from "react";

import { isFieldValid } from "../../services/utils";
import Button from "../Button";
import ErrorFormMessage from "../ErrorFormMessage";

export default function Radio({ fields, onSubmit, renderTitleText }) {
  const [error, setError] = useState();
  const [data, setData] = useState();
  let errorClass = "";

  const submitData = (e) => {
    setError(false);

    const value = e.target.value;
    const id = e.target.id;
    if (!value || value === "") {
      return;
    }
    setData({ id: id, value: value });
  };

  const clickNext = () => {
    const check = isFieldValid(data, "radio");
    if (check !== true) {
      setError(check);
      return;
    }

    onSubmit([data]);
  };

  if (error) {
    errorClass = "border-red-500 text-red-500";
  }

  return (
    <div className="">
      <div className="mb-5 px-1">
        {fields.map((el) => {
          return (
            <div key={el._id} className="flex items-center gap-2 mb-2">
              <input
                id={el._id}
                type="radio"
                value="1"
                name="default-radio"
                onChange={submitData}
                checked={data?.id === el._id}
                className={`w-4 h-4 text-dark-blue bg-white border-dark-blue border-1 cursor-pointer focus:ring-2 ${errorClass}`}
              />
              <label
                htmlFor={el._id}
                className={`text-sm font-interbold text-dark-blue cursor-pointer leading-tight ${errorClass}`}
              >
                {renderTitleText(el.label)}
              </label>
            </div>
          );
        })}
        {error ? <ErrorFormMessage message={error} /> : null}
      </div>
      <Button theme="dark" title="Nästa" onClick={clickNext} />
    </div>
  );
}
