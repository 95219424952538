import "../assets/styles/stripe.scss";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React from "react";

import CheckoutForm from "./CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(publishableKey);

export default function App({ clientSecret }: { clientSecret: string }) {
  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        // colorPrimary: "#002733",
      },
      rules: {
        ".Input": {
          color: "#fff",
          borderWidth: "2px",
          borderRadius: "99999px",
          borderColor: "#fff",
          backgroundColor: "transparent",
        },
        ".Input::placeholder": {
          color: "#FFFFFF80",
        },
        ".Label": {
          color: "#fff",
          marginBottom: "10px",
        },
      },
    },
    locale: "sv",
  };

  return (
    <div className="checkout-form">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
