import "../../assets/styles/react-datepicker.css";

import sv from "date-fns/locale/sv";
import React, { useState } from "react";
import Dpk, { registerLocale } from "react-datepicker";

import { ErrorFields, VariablesList } from "../../types/entities";
import Input from "../Input";

registerLocale("sv", sv);

type Props = {
  id: string;
  onSubmit: (e: { target: { id: string; value: string } }) => void;
  error?: React.ReactElement | boolean;
};

export default function DatePicker({ id, onSubmit, error }: Props) {
  const [startDate, setStartDate] = useState<Date>();

  const formatDate = (d: Date) => {
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const setData = (date: Date) => {
    setStartDate(date);
    onSubmit({ target: { id: id, value: formatDate(date) } });
  };

  return (
    <div className="max-w-[300px]">
      <Dpk
        locale="sv"
        dateFormat="yyyy-MM-dd"
        selected={startDate}
        onChange={(date: Date) => setData(date)}
        inline
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      <div className="mt-1">
        <Input
          type="text"
          name="dt"
          error={error}
          value={startDate ? formatDate(startDate) : ""}
          onChange={() => null}
          readonly
        />
      </div>
    </div>
  );
}
