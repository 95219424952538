import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import * as store from "store";

import Layout from "./components/Layout";
import LayoutHomepage from "./components/LayoutHomepage";
import CheckoutPage from "./pages/CheckoutPage";
import ContractPage from "./pages/ContractPage";
import HierarchyPage from "./pages/HierarchyPage";
import IndexPage from "./pages/IndexPage";

export default function App() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get("utm_source");
    const medium = params.get("utm_medium");
    const campaign = params.get("utm_campaign");
    const term = params.get("utm_term");
    const content = params.get("utm_content");

    if (source || medium || campaign || term || content) {
      store.set("fnxUtm", {
        sourceUtmSource: source ?? "",
        sourceUtmMedium: medium ?? "",
        sourceUtmCampaign: campaign ?? "",
        sourceUtmContent: content ?? "",
        sourceUtmTerm: term ?? "",
      });
    }
  }, []);
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="hierarchy/:contractId" element={<HierarchyPage />} />
        <Route path="contract/:contractId" element={<ContractPage />} />
        <Route path="checkout/:data" element={<CheckoutPage />} />
      </Route>
      <Route element={<LayoutHomepage />}>
        <Route path="/" element={<IndexPage />} />
      </Route>
    </Routes>
  );
}
