const apiUrl: string = process.env.IDA_API_URL;
import * as store from "store";

import {
  ContractSet,
  ContractSubmit,
  ContractSubmitResponse,
} from "../types/entities";

export async function getContract(id: string): Promise<ContractSet> {
  const response = await fetch(`${apiUrl}/public/contract/template/${id}`, {
    method: "GET",
  }).catch((error: string) => {
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as ContractSet;
    return data ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}

export async function downloadContract(data: object): Promise<Blob> {
  const utm = store.get("fnxUtm");
  const response = await fetch(`${apiUrl}/public/contract?test=true`, {
    method: "POST",
    body: JSON.stringify({ ...data, ...utm }),
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error: string) => {
    throw Error(error);
  });

  if (response.ok) {
    return response.blob();
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}

export async function submitContract(
  data: ContractSubmit
): Promise<ContractSubmitResponse> {
  const utm = store.get("fnxUtm");
  const response = await fetch(`${apiUrl}/public/contract?test=false`, {
    method: "POST",
    body: JSON.stringify({ ...data, ...utm }),
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error: string) => {
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as { paymentClientSecret: string };
    return data ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}
