import "../assets/styles/stripe.scss";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../components/Button";
import Checkout from "../components/Checkout";
import CtmPhone from "../components/CtmPhone";
import LoaderScreen from "../components/LoaderScreen";
import MainTitle from "../components/MainTitle";
import contracts from "../services/contracts";
import { decodeBase64, formatPrice } from "../services/utils";
import { CheckoutObject, Contract } from "../types/entities";

export default function Index() {
  const [contract, setContract] = useState<Contract>({} as Contract);
  const [title, setTitle] = useState("");
  const [token, setToken] = useState("");
  const [price, setPrice] = useState("");
  const [oldPrice, setOldPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const { data } = useParams<{
    data: string;
  }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!data) {
      return;
    }

    const dataObj: CheckoutObject = decodeBase64(data);
    if (!dataObj) {
      return;
    }

    const contractId = dataObj.contractId;
    if (!contractId) {
      return;
    }

    const t = dataObj.token;
    if (t) {
      setToken(t);
    }

    const p = dataObj.price;
    if (p) {
      setPrice(p);
    }

    const op = dataObj.oldPrice;
    if (op && p && op !== p) {
      setOldPrice(op);
    }

    const res = contracts().filter((el) => {
      return el.id === contractId;
    });
    if (res.length === 0) {
      return;
    }
    setContract(res[0]);

    if (!t) {
      setPaymentStatus(true);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (
      !params.has("payment_intent_client_secret") ||
      !params.has("redirect_status")
    ) {
      return;
    }

    const t = params.get("payment_intent_client_secret");
    const s = params.get("redirect_status");
    if (t !== token) {
      return;
    }
    setPaymentStatus(true);
    if (s && s !== "succeeded") {
      setPaymentError(s);
    }
  }, [contract]);

  useEffect(() => {
    let title = "";

    if (paymentStatus) {
      title = paymentError
        ? "Payment status"
        : "Ditt avtal är betalat och skickat";
    } else {
      title = "Bara betalningen kvar";
    }

    setTitle(title);
    document.title = `${title} - Fenix`;
  }, [paymentStatus, paymentError]);

  if (!contract) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <>
      {loading && <LoaderScreen />}
      {!paymentStatus ? (
        <>
          <MainTitle title={title} />
          <div className="text-white mb-10 max-w-full sm:max-w-[500px]">
            <div className="text-[18px] lg:text-[24px] mb-2 lg:mb-4">
              <span className="font-interbold pr-1">{formatPrice(price)}</span>{" "}
              kr
              {oldPrice ? (
                <span className="line-through decoration-1 pl-3 text-[13px] lg:text-[19px] opacity-70">
                  {formatPrice(oldPrice)} kr
                </span>
              ) : null}
            </div>
            <p className="mb-5">
              Fyll i dina kortuppgifter nedan och när betalningen är genomförd
              skickar vi ditt avtal och ett kvitto till den e-postadress som du
              angivit.
            </p>
            {token && <Checkout clientSecret={token} />}
          </div>
        </>
      ) : null}
      {paymentStatus && paymentError === "" ? (
        <>
          <MainTitle title={title} />
          <div className="text-white mb-10 max-w-full lg:max-w-2xl">
            <p className="mb-5">
              Tack för förtroendet. Behöver du hjälp med ytterligare avtal så
              vet du hur man gör nu. Är det något du undrar över kan du alltid
              kontakta oss på{" "}
              <a className="underline" href="mailto:kund@fenixfamily.se">
                kund@fenixfamily.se
              </a>{" "}
              eller ringa <CtmPhone />.
            </p>

            <Button title="Stäng" onClick={() => navigate("/")} />
          </div>
        </>
      ) : null}
      {paymentStatus && paymentError !== "" ? (
        <>
          <MainTitle title={title} />
          <div className="text-white mb-10 max-w-full lg:max-w-2xl">
            <p className="mb-5">{paymentError}</p>

            <Button title="Stäng" onClick={() => navigate("/")} />
          </div>
        </>
      ) : null}
    </>
  );
}
