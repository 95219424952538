import React from "react";

export default function ProgressBar({ value = 0 }) {
  return (
    <div
      className="flex w-full bg-white rounded-full"
      style={{ boxShadow: "0px 18px 21px 11px rgba(0, 0, 0, 0.2)" }}
    >
      <div
        className={`bg-dark-yellow rounded-full h-[20px] transition-all`}
        style={{ width: `${value}%` }}
      />
    </div>
  );
}
