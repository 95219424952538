import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { dataDeepCopy } from "../services/utils";
import {
  AnswerData,
  AnswersLog,
  AnswersLogItem,
  ContractSet,
  CurrentQuestion,
  HierarchyItem,
  QuestionItem,
  QuestionTree,
  VariablesList,
} from "../types/entities";
import Button from "./Button";
import CtmPhone from "./CtmPhone";
import Modal from "./Modal";
import ProgressBar from "./ProgressBar";
import Question from "./Question";

type Qeul = (
  question: HierarchyItem,
  tree: QuestionTree,
  depth: number
) => Qeul | boolean;

type Props = {
  data: ContractSet;
  answersLog: AnswersLog;
  setAnswersLog: React.Dispatch<React.SetStateAction<AnswersLog>>;
  submitAnswers: () => void;
};

export default function QuestionList({
  data,
  answersLog,
  setAnswersLog,
  submitAnswers,
}: Props) {
  const [hierarchy, setHierarchy] = useState<HierarchyItem[]>();
  const [questions, setQuestions] = useState<QuestionItem[]>();
  const [currentQuestion, setCurrentQuestion] = useState<CurrentQuestion>();
  const [currentDepth, setCurrentDepth] = useState(0);
  const [questionTree, setQuestionTree] = useState<QuestionTree>();
  const [finished, setFinished] = useState(false);
  const [progress, setProgress] = useState(1);
  const [variables, setVariables] = useState<VariablesList>({});
  const [showReloadModal, setShowReloadModal] = useState(false);

  const [debug] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const currentRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (data === undefined) {
      return;
    }
    setQuestions(data.questions);

    const hierarchyFlat = [] as Array<HierarchyItem>;
    for (const [k, v] of Object.entries(data.hierarchy)) {
      for (const [i, obj] of Object.entries(v)) {
        hierarchyFlat.push(obj);
      }
    }
    setHierarchy(hierarchyFlat);
  }, [data]);

  useEffect(() => {
    if (hierarchy === undefined) {
      return;
    }

    // get root question list
    const rootQuestionList = hierarchy.filter((el) => {
      return el.depth === currentDepth;
    });

    const baseQuestionTree: QuestionTree = {};
    data.hierarchy.map((el, ind) => {
      baseQuestionTree[ind] = { index: 0, list: [] };
    });
    baseQuestionTree[0].list = rootQuestionList;
    setQuestionTree(baseQuestionTree);

    formatAndSetCurrentQuestion(rootQuestionList[0]);
  }, [hierarchy]);

  useLayoutEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const wrapperHeight: number = wrapperRef.current.clientHeight;

    let topOffset = 0;
    if (scrollRef.current) {
      topOffset = scrollRef.current?.offsetTop - 10;
    }

    wrapperRef.current?.scrollTo({
      top: topOffset,
      left: 0,
      behavior: "smooth",
    });
    if (wrapperHeight) {
      currentRef.current?.setAttribute(
        "style",
        `min-height: ${wrapperHeight}px`
      );
    }
  }, [answersLog]);

  useEffect(() => {
    if (!questionTree) {
      return;
    }
    const rootLvl = questionTree[0];
    const total = rootLvl.list.length;
    const current = rootLvl.index;
    let progressValue = (current / total) * 100;
    if (progressValue === 0) {
      progressValue = 3;
    }

    setProgress(progressValue);
  }, [currentQuestion]);

  const formatAndSetCurrentQuestion = (
    hierarchyQuestion: HierarchyItem
  ): CurrentQuestion | false => {
    const question = getQuestionById(hierarchyQuestion.id);
    if (!question) return false;

    const questionFormatted = {
      q: question,
      h: hierarchyQuestion,
    };
    setCurrentQuestion(questionFormatted);
    return questionFormatted;
  };

  const getQuestionById = (id: string): QuestionItem | false => {
    if (!questions) return false;

    const res = questions.filter((element) => {
      return element._id === id;
    });
    return res.length > 0 ? res[0] : false;
  };

  const ifQuestionExistsInUpperLevels: Qeul = (question, tree, depth) => {
    // console.log("tree", tree);
    // console.log("depth", depth);
    // console.log("question", question);
    const upDepth = depth - 1;
    if (upDepth < 0) {
      return false;
    }

    if (!tree[upDepth] || !tree[upDepth].list) {
      return false;
    }

    const res = tree[upDepth].list.filter((el) => {
      return el.id === question.id;
    });
    // console.log("res", res);

    if (res.length > 0) {
      return true;
    }

    return ifQuestionExistsInUpperLevels(question, tree, depth - 1);
  };

  const submitQuestionData = (answerData: AnswerData[]) => {
    if (!currentQuestion || !questionTree) return false;

    const log = dataDeepCopy(answersLog);
    const structuredAnswers: AnswersLogItem = {};
    answerData.map((el) => {
      structuredAnswers[el.id] = { value: el.value, selected: true };
    });
    log[currentQuestion.q._id] = { fields: structuredAnswers };
    setAnswersLog(log);

    let tree = dataDeepCopy(questionTree);
    let depth = currentDepth;
    let zeroIndex = false;

    // check if answer has nested questions
    const answerId = answerData.length === 1 ? answerData[0].id : false;
    const answerFids = currentQuestion.h.fids;
    if (
      answerId &&
      answerFids &&
      answerFids[answerId] &&
      answerFids[answerId].length > 0
    ) {
      const nestedIds = answerFids[answerId];
      depth += 1;

      tree = insertQuestionsIntoTree(nestedIds, tree, depth);
      zeroIndex = true;
    }

    getNextQuestion(tree, depth, zeroIndex);
  };

  const getNextQuestion = (
    tree: QuestionTree,
    depth: number,
    zeroIndex = false
  ) => {
    // proceed with current depth
    const nextIndex = zeroIndex ? tree[depth].index : tree[depth].index + 1;
    if (tree[depth].list[nextIndex]) {
      const hierarchyQuestion = tree[depth].list[nextIndex];
      tree[depth].index = nextIndex;
      setQuestionTree(() => tree);

      const questionExist = ifQuestionExistsInUpperLevels(
        hierarchyQuestion,
        tree,
        depth
      );

      if (questionExist) {
        let zeroIndex = false;
        // console.log("questionExist", hierarchyQuestion);

        // check if question was answered
        const hasAnswerLogged = answersLog[hierarchyQuestion.id];
        // console.log("hasAnswerLogged", hasAnswerLogged);
        if (hasAnswerLogged) {
          const answeredId = Object.keys(
            answersLog[hierarchyQuestion.id].fields
          )[0];

          // console.log("nestedIds", hierarchyQuestion.fids[answeredId]);
          if (
            hierarchyQuestion.fids[answeredId] &&
            hierarchyQuestion.fids[answeredId].length > 0
          ) {
            const nestedIds = hierarchyQuestion.fids[answeredId];
            depth += 1;
            tree = insertQuestionsIntoTree(nestedIds, tree, depth);
            zeroIndex = true;
            // console.log("tree", tree);
          }
        }

        getNextQuestion(tree, depth, zeroIndex);
        return;
      }
      formatAndSetCurrentQuestion(hierarchyQuestion);
      return;
    }

    // move depth up
    getNextQuestionDepthUp(tree, depth);
  };

  const getNextQuestionDepthUp = (tree: QuestionTree, depth: number) => {
    if (depth === 0) {
      setFinished(true);
      wrapperRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }

    const newDepth = depth - 1;
    const nextIndex = tree[newDepth].index + 1;
    if (tree[newDepth].list[nextIndex]) {
      const hierarchyQuestion = tree[newDepth].list[nextIndex];
      formatAndSetCurrentQuestion(hierarchyQuestion);
      tree[newDepth].index = nextIndex;
      setCurrentDepth(newDepth);
      setQuestionTree(() => tree);
      return;
    }
    getNextQuestionDepthUp(tree, newDepth);
  };

  const insertQuestionsIntoTree = (
    ids: Array<string>,
    tree: QuestionTree,
    depth: number
  ): QuestionTree => {
    const hierarchyQuestions: Array<HierarchyItem> = [];
    ids.map((id) => {
      for (const [k, v] of Object.entries(data.hierarchy[depth])) {
        if (k === id) {
          hierarchyQuestions.push(v);
        }
      }
    });

    tree[depth].list = hierarchyQuestions;
    tree[depth].index = 0;
    setCurrentDepth(depth);
    setQuestionTree(tree);

    return tree;
  };

  const renderQuestion = () => {
    if (currentQuestion === undefined) {
      return;
    }
    return (
      <Question
        key={currentQuestion.q._id}
        question={currentQuestion.q}
        submitQuestionData={submitQuestionData}
        variables={variables}
        setVariables={setVariables}
        renderTitleText={renderTitleText}
      />
    );
  };

  const renderTitleText = (title: string) => {
    if (Object.entries(variables).length === 0) {
      return title;
    }

    const reg = RegExp("\\[([\\w\\d\\s]+)\\]", "g");
    for (const match of title.matchAll(reg)) {
      const key = match[1].split(" ").join("").toLowerCase();
      title = title.replace(`[${match[1]}]`, variables[key]);
    }
    return title;
  };

  const renderAnsweredQuestions = () => {
    const questionIds = [];
    for (const [key, value] of Object.entries(answersLog)) {
      questionIds.push(key);
    }

    const questionsRender = questionIds.map((i) => {
      const q = getQuestionById(i);
      if (!q) return null;

      return (
        <div key={q._id}>
          <Question
            key={q._id}
            question={q}
            completed={true}
            variables={variables}
            renderTitleText={renderTitleText}
          />
        </div>
      );
    });

    return (
      <div className="relative">
        <div className="w-1 h-full absolute bg-light-yellow top-0 left-[30px]" />
        <div className="w-1 absolute bg-gradient-to-t from-white to-light-yellow bottom-0 left-[30px] h-[40] z-[1]" />
        {questionsRender}
        <div ref={scrollRef} />
        <div className="relative text-light-yellow font-interbold mb-5 flex flex-row pl-[25] z-[2] leading-none">
          <div className="h-[14] w-[14] bg-light-yellow rounded-full mr-4" />
          <span>{t("finished")}</span>
        </div>
      </div>
    );
  };

  const renderSummaryQuestions = () => {
    const questionIds = [];
    for (const [key, value] of Object.entries(answersLog)) {
      questionIds.push(key);
    }

    return questionIds.map((i) => {
      const question = getQuestionById(i);
      if (!question) return null;

      const answerFields = answersLog[i]?.fields;
      return (
        <div key={question._id} className="mb-5">
          <h1 className="font-pprightgothic text-[24px] leading-none text-dark-blue mb-2">
            {renderTitleText(question.label)}
          </h1>
          {question.fields.map((f) => {
            if (answerFields[f._id]) {
              const val = answerFields[f._id].value;
              return (
                <p key={f._id} className="leading-tight">
                  <span
                    className={
                      question.type === "singleAnswer" ? "font-interbold" : ""
                    }
                  >
                    {renderTitleText(f.label)}
                  </span>
                  <span className="font-interbold">
                    {question.type !== "singleAnswer" ? ` ${val}` : ""}
                  </span>
                </p>
              );
            }
          })}
        </div>
      );
    });
  };

  return (
    <>
      {finished ? (
        <div className="relative shadow-default bg-white rounded-lg px-3 sm:px-5 py-6 mb-4">
          <h1 className="font-pprightgothic text-[28px] leading-none text-dark-blue mb-3">
            Så här har du svarat
          </h1>
          <div className="mb-4">
            Gå igenom dina svar en extra gång och är du nöjd, välj{" "}
            <em>Godkänt</em>. Vill du ändra i svaren, välj <em>Ändra svar</em>.
          </div>
          <div className="flex gap-5 mb-7">
            <Button
              title={t("restart-btn")}
              onClick={() => {
                setShowReloadModal(true);
              }}
              theme="dark-transparent"
            />
            <Button
              title={t("complete-btn")}
              onClick={submitAnswers}
              theme="dark"
            />
          </div>
          <hr className="pb-2 mb-5" />
          {renderSummaryQuestions()}

          {showReloadModal && (
            <Modal title="" onClose={() => setShowReloadModal(false)}>
              <div className="w-full max-w-[590px] mt-3">
                <p className="mb-5">
                  Vill du ändra dina svar i Avtalshjälpen? Klicka på “Ändra
                  svar” så kommer du till Steg 1 igen.
                </p>
                <p>
                  Har du frågor eller behöver hjälp med hur du ska besvara en
                  viss fråga är du alltid välkommen att höra av dig till oss.
                  Mejla till{" "}
                  <a className="underline" href="mailto:kund@fenixfamily.se">
                    kund@fenixfamily.se
                  </a>{" "}
                  eller ring <CtmPhone />.
                </p>
              </div>
              <div className="flex flex-col lg:flex-row justify-between mt-7 gap-4">
                <Button
                  title="Tillbaka till summeringen"
                  onClick={() => setShowReloadModal(false)}
                  theme="dark-transparent"
                />
                <Button
                  title="Ändra svar"
                  onClick={() => location.reload()}
                  theme="dark"
                />
              </div>
            </Modal>
          )}
        </div>
      ) : (
        <>
          <div className="mb-5">
            <ProgressBar value={progress} />
          </div>
          <div
            className="overflow-y-auto px-1 flex-grow flex flex-col"
            ref={wrapperRef}
          >
            {Object.entries(answersLog).length > 0 && renderAnsweredQuestions()}

            <div className="relative flex-grow" ref={currentRef}>
              <div className="relative text-white font-interbold mb-5 flex flex-row pl-[25] z-[2] leading-none">
                <div className="h-[14] w-[14] bg-white rounded-full mr-4" />
                <span>{t("in-progress")}</span>
              </div>
              <div className="w-1 h-full absolute bg-white top-0 left-[30px]" />
              {renderQuestion()}
            </div>

            {debug && (
              <div className="text-white bg-dark-blue p-10 rounded-xl">
                <p className="font-bold mt-10">Debug info</p>
                <hr />

                <h1 className="text-sm font-bold my-3">Answers</h1>
                {Object.keys(answersLog).length > 0 ? (
                  <pre className="text-[11px] overflow-auto w-full border">
                    {JSON.stringify(answersLog, null, 2)}
                  </pre>
                ) : null}

                <h1 className="text-sm font-bold my-3">Current question</h1>
                <pre className="text-[11px] overflow-auto w-full border">
                  {JSON.stringify(currentQuestion, null, 2)}
                </pre>

                <h1 className="text-sm font-bold my-3">Question tree</h1>
                <pre className="text-[11px] overflow-auto w-full border">
                  {JSON.stringify(questionTree, null, 2)}
                </pre>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
