import parse from "html-react-parser";
import React, { useLayoutEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { generateRandomString } from "../services/utils";
import { AnswerData, QuestionItem, VariablesList } from "../types/entities";
import Input from "./fields/Input";
import Radio from "./fields/Radio";
import HelpMessage from "./HelpMessage";

const arrowIconDark = new URL(
  "../assets/images/question-arrow-dark.svg",
  import.meta.url
);
const arrowIcon = new URL(
  "../assets/images/question-arrow.svg",
  import.meta.url
);

type Props = {
  question: QuestionItem;
  submitQuestionData?: (a: AnswerData[]) => void;
  setVariables?: React.Dispatch<React.SetStateAction<VariablesList>>;
  variables: VariablesList;
  renderTitleText: (t: string) => string;
  completed?: boolean;
};

export default function Question({
  question,
  submitQuestionData,
  setVariables,
  variables,
  renderTitleText,
  completed = false,
}: Props) {
  const [expand, setExpand] = useState(!completed);
  const [height, setHeight] = useState(0 as number);
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, []);

  const defaultClasses = ["overflow-hidden", "transition-[max-height]"];

  const transitionStyles = (st: string) => {
    const styles: { [k: string]: { maxHeight: string | number } } = {
      entering: { maxHeight: `${height > 0 ? `${height}px` : "initial"}` },
      entered: { maxHeight: `${height > 0 ? `${height}px` : "initial"}` },
      exiting: { maxHeight: 0 },
      exited: { maxHeight: 0 },
    };
    return styles[st] ?? null;
  };

  const onNextClick = (data: AnswerData[]) => {
    if (submitQuestionData) {
      submitQuestionData(data);
    }
  };

  const checkForRange = () => {
    const res = question.fields.filter((el) => {
      return el.format === "percentage";
    });
    return res.length >= 1;
  };

  const renderFields = () => {
    const type = question.type;
    if (type === "singleAnswer") {
      return (
        <Radio
          fields={question.fields}
          onSubmit={onNextClick}
          key={generateRandomString()}
          renderTitleText={renderTitleText}
        />
      );
    }
    if (type === "freeText") {
      return (
        <Input
          fields={question.fields}
          onSubmit={onNextClick}
          key={generateRandomString()}
          variables={variables}
          setVariables={setVariables}
          renderTitleText={renderTitleText}
        />
      );
    }

    return null;
  };

  const toggleExpand = () => {
    if (completed) {
      return;
    }
    setExpand(!expand);
  };

  let bgColor = "bg-white";
  if (completed) {
    bgColor = "bg-light-yellow";
  }

  return (
    <div
      className={`relative shadow-default ${bgColor} rounded-lg px-3 py-4 md:px-7 md:py-6 mb-5`}
    >
      <div className="flex flex-row items-center flex-1 justify-between">
        <h1
          className={`font-pprightgothic text-[24px] sm:text-[34px] leading-none text-dark-blue max-w-full lg:max-w-3xl ${
            !completed ? "cursor-pointer" : ""
          }`}
          // onClick={toggleExpand}
        >
          {renderTitleText(question.label)}
        </h1>
        {/*        {!completed && (
          <div
            className={`p-1 transition hover:cursor-pointer ${
              expand ? "rotate-0" : "rotate-180"
            }`}
            onClick={toggleExpand}
          >
            <img src={arrowIconDark} width="25" height="25" />
          </div>
        )}*/}
      </div>
      {!completed ? (
        <CSSTransition in={expand} timeout={150} nodeRef={ref}>
          {(state) => (
            <div
              className={defaultClasses.join(" ")}
              // style={{ ...transitionStyles(state) }}
              ref={ref}
            >
              <div className="pt-3 max-w-full flex flex-col lg:flex-row justify-between gap-5">
                <div className="w-full lg:max-w-2xl">
                  {question.instructions.length > 0 && !checkForRange() ? (
                    <div className="text-sm mb-3">
                      {parse(renderTitleText(question.instructions))}
                    </div>
                  ) : null}

                  {renderFields()}
                </div>
                <div className="w-full md:max-w-[300px]">
                  <HelpMessage />
                </div>
              </div>
            </div>
          )}
        </CSSTransition>
      ) : null}
    </div>
  );
}
