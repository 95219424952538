import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../components/Button";
import CtmPhone from "../components/CtmPhone";
import LoaderScreen from "../components/LoaderScreen";
import MainTitle from "../components/MainTitle";
import Modal from "../components/Modal";
import QuestionList from "../components/QuestionList";
import SubmitForm from "../components/SubmitForm";
import contracts from "../services/contracts";
import { getContract } from "../services/ida";
import { encodeBase64, formatPrice } from "../services/utils";
import {
  AnswersLog,
  CheckoutObject,
  Contract,
  ContractSet,
  ContractSubmitResponse,
} from "../types/entities";

export default function ContractPage() {
  const [data, setData] = useState<ContractSet>();
  const [loading, setLoading] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [answersLog, setAnswersLog] = useState<AnswersLog>({} as AnswersLog);
  const [contract, setContract] = useState<Contract>({} as Contract);
  const { contractId } = useParams<{ contractId: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const res = contracts().filter((el) => {
      return el.id === contractId;
    });
    if (res.length === 0) {
      return;
    }
    setContract(res[0]);
  }, []);

  useEffect(() => {
    document.title = `${contract.title} - Fenix`;
  }, [contract]);

  const startQuiz = () => {
    setLoading(true);

    getContract(contract.id)
      .then((dt) => {
        setData(dt);
      })
      .catch((error: Error) => {
        Sentry.captureException(error);
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitAnswers = () => {
    setSubmitForm(true);
  };

  if (submitted) {
    return (
      <>
        <MainTitle title="Nu är ditt avtal skickat!" />
        <div className="text-white mb-10 max-w-full lg:max-w-2xl">
          <p className="mb-5">
            Tack för förtroendet. Behöver du hjälp med ytterligare avtal så vet
            du hur man gör nu. Är det något du undrar över kan du alltid
            kontakta oss på{" "}
            <a className="underline" href="mailto:kund@fenixfamily.se">
              kund@fenixfamily.se
            </a>{" "}
            eller ringa <CtmPhone />.
          </p>

          <Button title="Stäng" onClick={() => navigate("/")} />
        </div>
      </>
    );
  }

  if (submitForm && contractId) {
    return (
      <>
        <MainTitle title="Ditt avtal är redo!" />
        <div className="text-white mb-10 max-w-full lg:max-w-2xl">
          <div className="text-[18px] lg:text-[24px] mb-2 lg:mb-4">
            <span className="font-interbold pr-1">
              {formatPrice(contract.price)}
            </span>
            kr
          </div>
          <p className="mb-5">
            Nu har du svarat på alla frågor och vi kan sätta samman ditt avtal.
            Fyll i namn, e-post och telefonnummer så skickar vi avtalet till
            dig.
          </p>

          <SubmitForm
            className="grid gap-4 grid-cols-1 sm:grid-cols-2"
            answersLog={answersLog}
            contractId={contract.id}
            contractType={contract.type}
            onSuccess={(data?: ContractSubmitResponse) => {
              let obj: CheckoutObject = {
                contractId: contractId,
                price: contract.price,
              };
              const respNormData = {} as CheckoutObject;
              if (data && data.paymentClientSecret) {
                respNormData.token = data.paymentClientSecret;
              }
              if (data && data.totalPriceAfterDiscount) {
                respNormData.price = data.totalPriceAfterDiscount;
              }
              if (data && data.totalPriceBeforeDiscount) {
                respNormData.oldPrice = data.totalPriceBeforeDiscount;
              }
              obj = { ...obj, ...respNormData };
              const dt = encodeBase64(obj);
              navigate(`/checkout/${dt}`);
            }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      {loading && <LoaderScreen />}
      {showCloseModal && (
        <Modal title="" onClose={() => setShowCloseModal(false)}>
          <div className="w-full max-w-[590px] mt-3">
            Väljer du att avbryta Avtalshjälpen innan alla frågor är besvarade
            försvinner svaren som du angivit. Du är naturligtvis välkommen att
            börja om med ditt avtal närsomhelst.
          </div>
          <div className="flex flex-col lg:flex-row justify-between mt-7 gap-4">
            <Button
              title="Fortsätt och slutför Avtalshjälpen"
              onClick={() => setShowCloseModal(false)}
              theme="dark-transparent"
            />
            <Button
              title="Avbryt Avtalshjälpen"
              onClick={() => location.reload()}
              theme="dark"
            />
          </div>
        </Modal>
      )}
      <MainTitle title={contract.title} />

      {data !== undefined ? (
        <>
          <div className="text-white mb-3 max-w-full lg:max-w-2xl">
            {contract.progress}
          </div>
          <div className="mb-5">
            <Button
              title="Avbryt Avtalshjälpen"
              onClick={() => setShowCloseModal(true)}
              theme="white-transparent"
              size="small"
            />
          </div>
          <QuestionList
            data={data}
            answersLog={answersLog}
            setAnswersLog={setAnswersLog}
            submitAnswers={submitAnswers}
          />
        </>
      ) : (
        <>
          <div className="text-white mb-7 max-w-full lg:max-w-2xl">
            <div className="text-[18px] lg:text-[24px] mb-4">
              <span className="font-interbold pr-1">
                {formatPrice(contract.price)}
              </span>
              kr
            </div>
            {contract.main}
            <div className="mt-5 text-[14px] text-gray-300">
              <p className="mb-2">
                * Just nu lider vi av tillfälliga tekniska problem, för att se
                sammanställningen av hela avtalet måste du fylla i alla steg. Vi
                vill därför be dig läsa igenom dina svar extra noggrant.
              </p>
              <p>
                Vi ber om ursäkt samt visat överseende medan vi jobbar på
                problemet.
              </p>
            </div>
          </div>
          <div className="flex gap-5">
            <Button
              title={t("back")}
              onClick={() => navigate("/")}
              theme="white-transparent"
            />
            <Button title={contract.mainCta} onClick={() => startQuiz()} />
          </div>
        </>
      )}
    </>
  );
}
