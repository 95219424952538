import React from "react";

import CtmPhone from "./CtmPhone";

export default function HelpMessage() {
  const texts = [
    <span key={1}>
      Behöver du hjälp med en fråga? Kontakta oss gärna på{" "}
      <a className="underline" href="mailto:kund@fenixfamily.se">
        kund@fenixfamily.se
      </a>{" "}
      eller ring <CtmPhone />.
    </span>,
    <span key={2}>
      Du är alltid välkommen att höra av dig till någon av våra rådgivare om det
      är något du undrar över.
    </span>,
    <span key={3}>
      Hos oss finns duktiga jurister som kan svara på alla dina frågor och
      funderingar. Maila gärna till{" "}
      <a className="underline" href="mailto:kund@fenixfamily.se">
        kund@fenixfamily.se
      </a>{" "}
      eller ring <CtmPhone />.
    </span>,
    <span key={4}>
      Behöver du råd och stöttning av en jurist eller rådgivare är det bara att
      höra av dig till oss på Fenix.
    </span>,
  ];

  const getRandomText = () => {
    return texts[Math.floor(Math.random() * texts.length)];
  };
  return (
    <div className="border border-dark-blue rounded-xl p-3 lg:p-4 font-interlight text-[14px] italic">
      {getRandomText()}
    </div>
  );
}
